<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-07-28 09:17:21
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 16:25:29
-->
<template>
  <div class="clan">
    <div>
      <p class="clan_title base_333_color">合同信息</p>
      <div class="clan_con base_666_color">
        <div class="left_clan">
          <p>购买人: {{ clanItem.accountName }}</p>
          <p>
            服务类型：{{
              clanItem.nurseType == "12"
                ? "24小时生活照护"
                : clanItem.nurseType == "14"
                ? "钟点服务"
                : clanItem.nurseType == "13"
                ? "短期应急"
                : clanItem.nurseType == "17"
                ? "适老无障碍设施改造"
                : "其他"
            }}
          </p>
          <p>购买数量：{{ clanItem.buyNumber }}</p>
          <p>门店：{{ clanItem.storeName || "暂未填写" }}</p>
          <p>实付金额（CNY）：{{ clanItem.totalAmount || "0" }}元</p>
        </div>
        <div class="right_clan">
          <p>老人姓名: {{ clanItem.accountFamilyName }}</p>
          <p>订购产品：{{ clanItem.orderSubject }}</p>
          <p>合同时间：{{ clanItem.createTime }}</p>
          <p>护理员：{{ clanItem.assistantName || "暂无" }}</p>
        </div>
      </div>
    </div>
    <div>
      <p class="clan_title base_333_color">老人信息</p>
      <div class="clan_con base_666_color">
        <div class="left_clan">
          <p>姓名: {{ clanItem.accountName }}</p>
          <p>联系方式: {{ clanItem.accountMobile }}</p>
          <!-- <p>身份证：{{ clanItem.familyMember.idCard }}</p> -->
        </div>
        <div class="right_clan">
          <p>医保卡号: {{ "暂未填写" }}</p>
          <p>服务地址: {{ clanItem.fullAddress }}</p>
        </div>
      </div>
    </div>
    <div>
      <p class="clan_title base_333_color">预约信息</p>
      <div class="clan_con base_666_color">
        <div class="left_clan">
          <p>预约开始日期: {{ clanItem.appointmentStartTime }}</p>
        </div>
      </div>
    </div>
    <div>
      <p class="clan_title base_333_color">销售负责人</p>
      <div class="clan_con base_666_color">
        <div class="left_clan">
          <p>销售负责人: {{ clanItem.salesman || "暂无" }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    clanItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style scoped>
.clan {
  padding-left: 70px;
}
.clan_con {
  display: flex;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 20px;
}
.clan_title {
  font-size: 22px;
}
.left_clan {
  flex: 1;
}
.right_clan {
  flex: 3;
}
</style>
