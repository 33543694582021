<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-10 09:00:33
 * @LastEditors: ------
 * @LastEditTime: 2021-07-07 09:26:03
-->
<template>
  <div class="nurseDetail clearfix ">
    <div class="nurseDetail_page">
      <div class=" nurseDetail_title base_333_color">
        <img src="../image/shouye(1).png" alt="" />

        <slot name="one"></slot>
        <slot name="two"> </slot>
        <slot name="three"> </slot>
      </div>
      <div class="nurseDetail_con">
        <slot name="slotCon"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "titleNav",
  props: {},
  methods: {},
};
</script>
<style scoped>
.nurseDetail {
  background: #fafafd;
  position: relative;
  /* height: 100%; */
}
.nurseDetail_page {
  width: 1782px;
  text-align: left;
  margin-left: 50px;
  /* position: relative; */
  padding-bottom: 80px;
}
.nurseDetail_title {
  padding: 28px 0;
  font-size: 22px;
}
.nurseDetail_title img {
  width: 22px;
  height: 22px;
  margin-right: 14px;
  margin-top: -4px;
}
.nurseDetail_con {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  /* display: flex;
  justify-content: space-between; */
  padding: 48px 196px 48px 58px;
  /* margin-bottom: 80px; */
}
</style>
