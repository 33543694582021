<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-08 11:19:44
 * @LastEditors: ------
 * @LastEditTime: 2021-07-29 16:14:46
-->
<template>
  <div class="rightList">
    <slot name="one"> </slot>
    <slot name="two"> </slot>
    <div class="goHome cursor" @click="go()">
      <img src="../image/shouye(2).png" alt="" />
      <span class="base_d4d4d4_color">返回首页</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightList",
  props: {},
  methods: {
    go() {
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped>
.rightList {
  background: #fff;
  height: 100%;
  width: 224px;
  box-shadow: -8px 0px 16px -8px rgba(205, 210, 243, 0.4);
}
.goHome {
  position: absolute;
  bottom: 65px;
  left: 60px;
}
.goHome img {
  width: 18px;
  height: 18px;
  /* vertical-align: text-top; */
  margin-top: -6px;
}
.goHome span {
  font-size: 20px;
  margin-left: 6px;
}
</style>
