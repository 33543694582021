<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-07-212 09:17:21
 * @LastEditors: ------
 * @LastEditTime: 2021-08-16 11:09:30
-->
<template>
  <div class="clan">
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-item label="服务对象姓名">
          {{ interviewlist.name ? interviewlist.name : null }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="服务对象年龄">
          {{ interviewlist.age || "未填写" }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="服务对象身高">
          {{ interviewlist.height || "未填写" }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="服务对象体重">
          {{ interviewlist.weight || "未填写" }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="服务对象性别">
          {{ interviewlist.sex_dictText ? interviewlist.sex_dictText : null }}
        </a-form-item>
      </a-col>
      <a-divider />

      <a-col :span="12">
        <a-form-item label="需求服务类型">
          {{
            interviewlist.nurseType_dictText
              ? interviewlist.nurseType_dictText
              : null
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="服务人员护理周期">
          {{
            interviewlist.medicalInsuranceCycle_dictText
              ? interviewlist.medicalInsuranceCycle_dictText
              : null
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="有无长护险">
          {{
            interviewlist.medicalInsuranceFlag_dictText
              ? interviewlist.medicalInsuranceFlag_dictText
              : null
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="长护险等级">
          {{
            interviewlist.medicalInsuranceLevel
              ? interviewlist.medicalInsuranceLevel + "级"
              : null
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="服务对象起夜情况">
          {{
            interviewlist.getUpNum ? interviewlist.getUpNum + "次" : "未填写"
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="家中人数">
          {{ interviewlist.familyNum ? interviewlist.familyNum + "人" : null }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="是否需要做饭">
          {{
            interviewlist.needCook_dictText
              ? interviewlist.needCook_dictText
              : null
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="服务人员住宿">
          {{ interviewlist.needStay ? interviewlist.needStay_dictText : null }}
        </a-form-item>
      </a-col>
      <a-divider />

      <a-col :span="12">
        <a-form-item label="协商价格">
          {{
            interviewlist.negotiatePrice + interviewlist.emType_dictText ||
              "未填写"
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="接受节假日另议价格">
          {{ interviewlist.changePrice_dictText || "未填写" }}
        </a-form-item>
      </a-col>
      <a-divider />

      <a-col :span="12">
        <a-form-item label="服务地址">
          {{
            interviewlist.serviceDetailAddress
              ? interviewlist.serviceDetailAddress
              : null
          }}
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="客户联系电话">
          {{ interviewlist.mobile ? interviewlist.mobile : null }}
        </a-form-item>
      </a-col>
      <a-divider />

      <a-col :span="24">
        <a-form-item label="服务详情要求">
          {{ interviewlist.serviceRequire || "未填写" }}
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item label="服务人员特殊要求">
          {{ interviewlist.specialRequire || "未填写" }}
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item label="护理要点和须知">
          {{ interviewlist.notice || "未填写" }}
        </a-form-item>
      </a-col>

      <a-col :span="24">
        <a-form-item label="备注">
          {{ interviewlist.remark || "未填写" }}
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    interviewlist: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style scoped>
.clan {
  padding-left: 70px;
}
.clan_con {
  display: flex;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 20px;
}
.clan_title {
  font-size: 22px;
}
.left_clan {
  flex: 1;
}
.right_clan {
  flex: 3;
}
</style>
